import { useDispatch } from 'react-redux';
import axios from 'axios';


const Logout = () => {
    const dispatch = useDispatch();
    initiateLogOut(true);
    dispatch({type : "auth/logout"});
    return <>Redirecting <div className='d-none'>{setTimeout(()=>{window.location.href="/dashboard"}, 50)}</div></>;
}

const initiateLogOut = (loggedOutOnServer=false) => {
    if (localStorage.getItem(process.env.REACT_APP_LOGGED_IN_LOCALSTORAGE_KEY) === null) {
        return; // Already logged out
    }
    localStorage.removeItem(process.env.REACT_APP_LOGGED_IN_LOCALSTORAGE_KEY);
    const logoutFromServer = async () => {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/logout`);
    }
    if (!loggedOutOnServer) {
        logoutFromServer();
    }
    else {
        window.location.reload();
    }
}

export default Logout;
export {initiateLogOut};